import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Route, Switch /*, Redirect */ } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

//Apollo and AppSync
import { ApolloProvider } from "react-apollo";
import { Rehydrated } from "aws-appsync-react";
import AWSAppSyncClient, { defaultDataIdFromObject } from "aws-appsync";
//Local Config
import appSyncConfig from "./AppSync";
//Components
import Header from './Header';
import Footer from './Footer';
//Components
/* Lazy load causes warning in browser console:
 * Failed prop type: Invalid prop `component` of type `object` supplied to `Route`, expected `function`.
 * Taking lazyload out resolves warning but then isn't lazy loading (of course)
 * Leaving lazy load in seems to work ok w.r.t. UX.  
 * Not sure how to fix warning just now as want to keep lazy load.
 */
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Category = lazy(() => import('./Category'));
const Product = lazy(() => import('./Product'));
const Article = lazy(() => import('./Article'));

const client = new AWSAppSyncClient({
  url: appSyncConfig.graphqlEndpoint,
  region: appSyncConfig.region,
  auth: {
    type: appSyncConfig.authenticationType,
    apiKey: appSyncConfig.apiKey,
  },
  cacheOptions: {
    dataIdFromObject: (obj) => {
      let id = defaultDataIdFromObject(obj);

      if (!id) {
        const { __typename: typename } = obj;
        switch (typename) {
          case 'Comment':
            return `${typename}:${obj.commentId}`;
          default:
            return id;
        }
      }

      return id;
    }
  }
});

function singlePage(){
  return (
    <ApolloProvider client={client}>
      <Rehydrated>
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <Header />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/category/:keyword" component={Category} />
                  <Route exact path="/product/:keyword" component={Product} />
                  <Route exact path="/article/:keyword" component={Article} />
                  <Route exact path="/about" component={About} />
                </Switch>
              <Footer />
            </Suspense>
          </Router>
      </Rehydrated>
    </ApolloProvider>
  );

}

ReactDOM.render(
  singlePage(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
