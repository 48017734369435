import React from 'react';
import {Link} from 'react-router-dom';
//Styling, Classes and Properties
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './AppStyles';
import Typography from '@material-ui/core/Typography';
//Page furniture
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import CameraIcon from '@material-ui/icons/PhotoCamera';
import CssBaseline from '@material-ui/core/CssBaseline';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
        <React.Fragment>
          <CssBaseline />
          <AppBar position="static" className={this.props.classes.appBar}>
            <Toolbar>
              {/*<CameraIcon className={this.props.classes.icon} />*/}
              <Link exact="true" to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="h6" color="inherit" noWrap>
                  findtrylike.com
                </Typography>
              </Link>
            </Toolbar>
          </AppBar>
        </React.Fragment>
      );
    }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);