const apiUrl = "https://fkl4qmkzevf57jpxb67khyqmiu.appsync-api.eu-west-1.amazonaws.com/graphql";
const apiKey = "da2-elqxqdxe6vbnzlkpinzypvzlim";

const config = {
  graphqlEndpoint: apiUrl,
  region: 'eu-west-1',
  authenticationType: 'API_KEY',
  apiKey,
  disableOffline: true
};

export default config;