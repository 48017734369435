import React from 'react';
//Styling, Classes and Properties
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './AppStyles';
import Typography from '@material-ui/core/Typography';
import {buildId} from './vars.json';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var theYear = new Date().getYear();
    if (theYear < 1900) {
      theYear = theYear + 1900;
    }
    return (
      <React.Fragment>
          {/* Footer */}
          <footer className={this.props.classes.footer}>
            <Typography variant="h6" align="center" gutterBottom>
              The Official Stuff
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
              &copy;findtrylike {theYear} |&nbsp;
              <a href="/legal/privacy-policy-uk.html" target="_self">Privacy Policy</a> |&nbsp;
              <a href="/legal/terms-of-use-uk.html" target="_self">Terms of Use</a> |&nbsp;
              <a href="/legal/cookie-policy-uk.html" target="_self">Cookie Policy</a> |&nbsp;
              <span title="Coming soon">Cookie Consent</span> |&nbsp;
              <a href="www.drinkaware.co.uk/">
                <img height="12pt" src="/images/drinkaware.png" alt="Please drink responsibly | Drinkaware" />
              </a> |&nbsp;
                {buildId}
            </Typography>
          </footer>
          {/* End footer */}
        </React.Fragment>
      );
    }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);